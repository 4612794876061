<template>
  <div>
    <router-view></router-view>
    <r-footer></r-footer>
  </div>
</template>

<script>
import RFooter from '@/components/RFooter.vue'
export default {
  components: {
    RFooter
  }
}
</script>
